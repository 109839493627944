<template>
    <div class="el">
        <Head :type="0">
            <template #title>
                <div>评价</div>
            </template>
        </Head>
        <div class="main">
            <div class="warp">
            <textarea name="" id=""  v-model="text"  placeholder="说说商家的亮点和不足吧~" ></textarea>

         <div class="lable">宝贝描述</div>
             <div class="box">
                    <van-grid :column-num="5" :border="false">
                                
                    <van-grid-item v-if="active<1"  :icon="require('../../assets/star_gery.png')" text="极差"  @click="change(1)" />
                    <van-grid-item v-if="active>=1" :icon="require('../../assets/img/readstar.png')" text="极差" @click="change(1)"  />
                    <van-grid-item v-if="active<2" :icon="require('../../assets/star_gery.png')" text="较差"  @click="change(2)" />
                    <van-grid-item v-if="active>=2" :icon="require('../../assets/img/readstar.png')" text="较差"  @click="change(2)" />

                    <van-grid-item v-if="active<3" :icon="require('../../assets/star_gery.png')" text="一般" @click="change(3)"  />
                    <van-grid-item v-if="active>=3" :icon="require('../../assets/img/readstar.png')" text="一般" @click="change(3)"  />

                    <van-grid-item v-if="active<4" :icon="require('../../assets/star_gery.png')" text="不错"  @click="change(4)" />
                    <van-grid-item v-if="active>=4" :icon="require('../../assets/img/readstar.png')" text="不错" @click="change(4)"  />

                    <van-grid-item v-if="active!=5" :icon="require('../../assets/star_gery.png')" text="很棒"  @click="change(5)" />
                    <van-grid-item v-if="active>=5" :icon="require('../../assets/img/readstar.png')" text="很棒"  @click="change(5)" />
  
                    </van-grid>
            </div>
            <div class="lable">卖家服务</div>
             <div class="box">
                    <van-grid :column-num="5" :border="false">
                                
                    <van-grid-item v-if="serviceScore<1"  :icon="require('../../assets/star_gery.png')"       text="极差"  @click="serviceScore = 1" />
                    <van-grid-item v-if="serviceScore>=1" :icon="require('../../assets/img/readstar.png')"    text="极差" @click="serviceScore = 1"  />
                    <van-grid-item v-if="serviceScore<2"  :icon="require('../../assets/star_gery.png')"       text="较差"  @click="serviceScore = 2" />
                    <van-grid-item v-if="serviceScore>=2" :icon="require('../../assets/img/readstar.png')"    text="较差"  @click="serviceScore = 2" />

                    <van-grid-item v-if="serviceScore<3"  :icon="require('../../assets/star_gery.png')"       text="一般" @click="serviceScore = 3"  />
                    <van-grid-item v-if="serviceScore>=3" :icon="require('../../assets/img/readstar.png')"    text="一般" @click="serviceScore = 3"  />

                    <van-grid-item v-if="serviceScore<4"  :icon="require('../../assets/star_gery.png')"       text="不错"  @click="serviceScore = 4" />
                    <van-grid-item v-if="serviceScore>=4" :icon="require('../../assets/img/readstar.png')"    text="不错" @click="serviceScore = 4"  />

                    <van-grid-item v-if="serviceScore!=5" :icon="require('../../assets/star_gery.png')"       text="很棒"  @click="serviceScore = 5" />
                    <van-grid-item v-if="serviceScore>=5" :icon="require('../../assets/img/readstar.png')"    text="很棒"  @click="serviceScore = 5" />
  
                    </van-grid>
            </div>
            <div class="lable">物流服务</div>
             <div class="box">
                    <van-grid :column-num="5" :border="false">
                                
                    <van-grid-item v-if="logisticsScore<1"  :icon="require('../../assets/star_gery.png')"     text="极差"  @click="logisticsScore = 1" />
                    <van-grid-item v-if="logisticsScore>=1" :icon="require('../../assets/img/readstar.png')"  text="极差"  @click="logisticsScore = 1 "  />
                    <van-grid-item v-if="logisticsScore<2"  :icon="require('../../assets/star_gery.png')" 		text="较差"  @click="logisticsScore = 2" />
                    <van-grid-item v-if="logisticsScore>=2" :icon="require('../../assets/img/readstar.png')" 	text="较差"  @click="logisticsScore = 2" />

                    <van-grid-item v-if="logisticsScore<3"  :icon="require('../../assets/star_gery.png')" 		text="一般"  @click="logisticsScore = 3"  />
                    <van-grid-item v-if="logisticsScore>=3" :icon="require('../../assets/img/readstar.png')" 	text="一般"  @click="logisticsScore = 3"  />

                    <van-grid-item v-if="logisticsScore<4"  :icon="require('../../assets/star_gery.png')" 		text="不错"  @click="logisticsScore = 4" />
                    <van-grid-item v-if="logisticsScore>=4" :icon="require('../../assets/img/readstar.png')" 	text="不错"  @click="logisticsScore = 4"  />

                    <van-grid-item v-if="logisticsScore!=5" :icon="require('../../assets/star_gery.png')" 		text="很棒"  @click="logisticsScore = 5" />
                    <van-grid-item v-if="logisticsScore>=5" :icon="require('../../assets/img/readstar.png')" 	text="很棒"  @click="logisticsScore = 5" />
  
                    </van-grid>
            </div>
            </div>
        </div>
        <div class="footer">
            <div class="btn" @click="up()">发布</div>
        </div>
    </div>
</template>

<script>
import Head from '../../components/head/index.vue'
import {evaluate} from '../../api/evaluate/index'
    export default {
        data(){
            return{
                active : 0,
                text  : '',
                serviceScore : 0,
								logisticsScore : 0
            }
        },
        components:{Head},
        methods:{

            change(data){
            this.active=data
            console.log(this.text);
            
            },
            up(){
                if(this.active<1){
                    this.$toast('请为商品打分')
                    return
                }

              
                console.log(this.text);
                evaluate({
									
									orderId				:	this.$route.query.id,
									goodsScore			:	this.active ,
									content				:	this.text ,
									shopId				:	this.$route.query.shopId ,
									serviceScore 		:   this.serviceScore  ,
									logisticsScore 	    :	this.logisticsScore ,
									orderItemId         :   this.$route.query.orderItemId
									
									} ).then(res=>{
                    

                    if(res.data.code===0){
                        this.$toast.success('评价成功')
                        this.$router.back()
                    }else{
                        this.$toast.fail('评价失败')
                    }

                })
            }
        },
        mounted(){ 
            this.setRem()
        }
    }
</script>

<style lang="less" scoped>
.el{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    .main{
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: .266667rem .133333rem;
        background-color: #F6F6F6;
        .warp{ 
            background-color: white;
            border-radius: .266667rem;
            padding: .266667rem;
            .box{ 
                width: 60%;
            }
        }
        .lable{
            color: #333333;
            font-size: .426667rem;
        }
        textarea{
           border: 1px solid #E0E0E0;
           width: 8.146667rem;
           height:2.666667rem;
           color: #999;
           padding: .266667rem;
           font-size: .32rem;
          font-family: PingFangSC-Regular, PingFang SC;
				margin: .266667rem 0 ;

				margin-left: 50%;
				transform: translateX(-50%);
        }
    }
    .footer{
        display: flex;
        align-items: center;
        justify-content: center;
        .btn{
            width:9.173333rem;
            height: .96rem;
            border-radius: .48rem;
            background-image: linear-gradient(to right ,#FF7200 , #FF3C00);
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: .373333rem;
            font-family: PingFangSC-Regular, PingFang SC;
            margin:  .266667rem 0;
        }
        padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
         padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    }
}
</style>